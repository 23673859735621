@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css?family=Roboto:200,300,400,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
.onScoll {
  background-color: rgba(255, 255, 255, 0.2);
  transform: translateY(-100px);
  transition: all 1.3s ease;
}
.headerMenu {
  display: none;
}
.localeSwitcher .localeSeparator {
    display: inline-block;
    width: 1px;
    height: 1em;
    background: black;
    margin: 0 8px;
    /* border-right: 1px solid white; */
}
/*
.localeSwitcher .locale:last-child:after {
    content: none;
}*/
.localeSwitcher .locale {
    padding: 2px;
}
.clickMenu {
  transition: .5s all ease-in;
  height: auto;
  background-color: rgba(255, 255, 255, 0.1);
  /*background-color: #ffffff;*/
  display: inline-block;
  padding-bottom: 6px;
}
.hideDropDown {
  display: none;
}
.loginModalHeader {
  text-align: center;
  /* margin-top: 20px; */
  margin: 30px
}
.loginModalTitle {
  text-align: center;
  height: 0;
  position: relative;
  bottom: 38px;
}
.loginModalTitle span {
  background: white;
  padding: 0 33px;
  /* font-weight: 700; */
  font-size: 20px;
  color: #bfbfbf;
}
.loginTextField {
  margin-top: 10px;
  text-align: left;
}
.facebookBox {
  text-align: center;
  padding: 8px;
  margin-top: 20px;
  background-color:	#3b5998;
}
.facebookText {
  color: #ffffff;
  font-size: 14px;
  margin-top: 5px;
}

/* .ant-tabs-nav-container {
  text-align: center;
} */

.ant-modal-content .ant-tabs-nav {
  display: inline-block;
  float: none;
}

.ant-modal-content .ant-tabs-nav .ant-tabs-tab {
  height: initial;
}

.form-search {
  margin-top: 20px;
  margin-bottom: unset;
}
.btu-search {
  height: 40px;
  width: 120px;
  background: #f9a01e;
  text-shadow: unset;
  border-color: #f9a01e;
  border-radius: 20px;
  color: #fff;
}

.btu-reset {
  height: 40px;
  width: 120px;
  background: #fff;
  text-shadow: unset;
  border-color: #fff;
  border-radius: 20px;
  color: #000;
}

.title-search-th {
  margin-bottom: unset;
  color: #000;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Sukhumvit Set';
  transform: translate(0, 10px);
}
.title-search-en {
  margin-bottom: unset;
  color: #000;
  font-weight: 400;
  margin-left: 7px;
}
.form-item-search {
  margin-bottom: unset;
}
.searchBox input#eventName.MuiInputBase-input.MuiInput-input {
  color: #000 !important;
}
.placeholder-search {
  color: #aaa; 
  font-weight: 400; 
  font-family: 'Sukhumvit Set';
}
.title-search {
  margin-bottom: unset;
  color: #1c81c4;
  font-weight: 400;
}
.title-search-icon {
  color: #1c81c4;
  font-size: 1.2em;
}
.ant-popover-inner-content {
  padding: 0;
}
.searchBox:hover {
  background-color: rgba(249, 160, 27, 0.08);
}
.searchBox.box1 {
  padding: 0 20px;
  border-right: 1px solid #D4D4D4;
}
.searchBox.box2 {
  padding: 0 20px;
  border-right: 1px solid #D4D4D4;
}
.searchBox.box3 {
  padding: 0 20px;
  display: flex;
  align-items: center;
}
@media only screen and (max-width : 576px) {
  .searchBox.box1 {
    border-right: none;
    border-bottom: 1px solid #D4D4D4;
  }
  .searchBox.box2 {
    border-right: 1px solid #D4D4D4;
    border-bottom: 1px solid #D4D4D4;
  }
  .searchBox.box3 {
    border-bottom: 1px solid #D4D4D4;
  }
  .title-search-th {
    transform: translate(0, 0);
    font-size: 12px;
  }
  .popup {
    top: 0;
  }
  span.seachEventInput {
      width: 200px;
  }
  .form-search {
    margin-top: 5px;
    margin-bottom: unset;
  }
  .btu-search {
    height: 32px;
  }
  .btu-reset {
    height: 32px;
  }
  .form-item-search {
    margin-bottom: unset;
    height: 26px;
  }
  .placeholder-search {
    font-size: 16px;
  }
  .searchBox input#eventName.MuiInputBase-input.MuiInput-input {
    font-size: 16px;
  }
}
@media only screen and (max-width : 335px) {
  span.seachEventInput {
      width: 178px;
  }
  .form-search {
    margin-top: 5px;
    margin-bottom: unset;
  }
  .btu-search {
    height: 32px;
  }
  .btu-reset {
    height: 32px;
  }
  .title-search-th {
    font-size: 1.2em;
  }
  .title-search-en {
    font-size: 1.2em;
  }
  .form-item-search {
    margin-bottom: unset;
    height: 26px;
  }
  .title-search {
    font-size: 20px !important;
  }
}


.inputBD {
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;

  border-top: black !important;
  border-left: black !important;
  border-right: black !important;
}

.footer {
  padding-top: 15px;
  height: 110px;
  width: 100%;
  background-color: #1b1c1d;
  text-align: center;
  color: white;
}
.footer a:first-of-type img {
  border: none;
  padding: 0 5px;
}
.footer img {
  height: 55px;
  display: inline-block;
  border-left: 1px solid dimgray;
}
.footer p {
  margin-top: 10px;
  color: #FFFFFF;
}
@media screen and (max-width: 414px) {
  .footer-logo {
    width: 100% !important;
  }
  .footer-logo-facebook {
    width: 5% !important;
  }
  .footer-logo-messenger {
    width: 6% !important;
  }
}
.inputBD .ant-input  {
    border-radius: none !important;
    -webkit-border-radius: none !important;
    -moz-border-radius: none !important;
    -ms-border-radius: none !important;
    -o-border-radius: none !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 1px solid #d9d9d9 !important;
}

.inputBD .ant-input:hover  {
    border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -ms-border-radius: 0px !important;
    -o-border-radius: 0px !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 3px solid rgba(0, 0, 0, 0.65) !important;
}

@font-face  {
    font-family:  'Sukhumvit Set';
    src:  url(/static/media/SukhumvitSet-Light.b14f3c3d.eot);
    src:  url(/static/media/SukhumvitSet-Light.636be0f1.ttf) format('truetype'),
            url(/static/media/SukhumvitSet-Light.2d896b36.svg) format('svg'),
            url(/static/media/SukhumvitSet-Light.258378a8.woff) format('woff'),
            url(/static/media/SukhumvitSet-Light.157ec406.woff2) format('woff2');
    font-style:  normal;
    font-weight:  normal;
}
@font-face  {
    font-family:  'Roboto';
    src:  url(/static/media/Roboto-Thin.321de678.ttf) format('truetype');
    font-style:  100;
    font-weight:  100;
}
@font-face  {
    font-family:  'Roboto';
    src:  url(/static/media/Roboto-Regular.11eabca2.ttf) format('truetype');
    font-style:  'normal';
    font-weight:  500;
}
@font-face  {
    font-family:  'Sukhumvit Set';
    src:  url(/static/media/SukhumvitSet-Light.b14f3c3d.eot);
    src:  url(/static/media/SukhumvitSet-Light.636be0f1.ttf) format('truetype'),
            url(/static/media/SukhumvitSet-Light.2d896b36.svg) format('svg'),
            url(/static/media/SukhumvitSet-Light.258378a8.woff) format('woff'),
            url(/static/media/SukhumvitSet-Light.157ec406.woff2) format('woff2');
    font-style:  normal;
    font-weight:  normal;
}
@font-face  {
    font-family:  'Sukhumvit Set';
    src:  url(/static/media/SukhumvitSet-Bold.71f90a92.eot);
    src:  url(/static/media/SukhumvitSet-Bold.d74b3e1e.ttf) format('truetype'),
            url(/static/media/SukhumvitSet-Bold.2a9a1aea.svg) format('svg'),
            url(/static/media/SukhumvitSet-Bold.fd169c8c.woff) format('woff'),
            url(/static/media/SukhumvitSet-Bold.98c09289.woff2) format('woff2');
    font-style:  normal;
    font-weight:  bold;
}
@font-face  {
    font-family: 'Sukhumvit Set';
    src:  url(/static/media/SukhumvitSet-SemiBold.d55ff127.ttf) format('truetype');
    font-style:  normal;
    font-weight:  600;
}
.ant-btn-primary {
    color: #fff;
    background-color: #f9a01e;
    border-color: #f9a01e;
}
.ant-btn-primary:hover, .ant-btn-primary:focus {
    color: #fff;
    background-color: #f9a01e;
    border-color: #f9a01e;
}
div.pageContent.pageContent.pageContent.pageContent.pageContent,
div.pageContent.pageContent.pageContent.pageContent.pageContent span,
div.pageContent.pageContent.pageContent.pageContent.pageContent p,
h2.pageContent.content-title
 {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    white-space: normal !important;
}
body{
  font-family: 'Roboto', 'Sukhumvit Set';
  background-color: #eee;
}

.ant-layout {
    background: gray;
    background: none;
}
.eventBottomDetailData .ant-tag {
    white-space: normal;
}
.ant-tag-orange {
    color: #000 !important;
}
.alignRight {
    text-align: right;
}
.alignCenter {
    text-align: center;
}
.hrDashBottom {
    border-bottom: 3px dashed rgba(0, 0, 0, 0.85);
    padding-bottom: 5px;
}
.sortable-sorting {
    box-shadow: 0 2px 6px gainsboro;
}
.ant-modal.responsiveModal {
    max-width: 789px;
    margin: auto;
    padding: 0;
    position: unset;
    display: flex;
    justify-content: center;
    width: auto !important;
}
.ant-modal.responsiveModal .ant-modal-close-x {
    /* width: 32px; */
    height: 36px;
    line-height: 39px;
    font-size: 25px;
    border-radius: 5px;
    margin: 1px;
}

.ant-modal.responsiveModal .ant-modal-body,
.ant-modal.responsiveModal .ant-modal-header,
{
    padding: 3%;
}
.ant-modal-body {
    padding: 8px;
}
.ant-tag.status {
    font-size: 14px;
    margin: 1px;
    white-space: pre-line;
    height: auto;
    position: relative;
    bottom: 3px;
}
div.ant-modal.editParticipantModal .ant-form-item-label {
    text-align: left;
    line-height: 24.9999px;
}
.radio-installment {
    width: 150px;
    height: 150px;
    background-color: #fff;
    border-color: #000;
    color: #000;
    text-shadow: unset;

}
@media only screen and (max-width: 600px) {
    .dividedBoxRegistrationStepsBG {
        display: none;
    }
    div.ant-modal.editParticipantModal .ant-form-item-label,
    div.ant-modal.editParticipantModal .ant-row.ant-form-item {
        padding: 0;
        margin: 3px;
    }
    .dividedBoxRegistrationSteps {
        margin-bottom: 2.5em !important;
        margin-top: 1em !important;
    }
    .deliveryBlock a {
        display: block;
    }
    .MuiStep-root.MuiStep-horizontal.MuiStep-alternativeLabel {
        padding: 0;
    }
    .radio-installment {
        width: 140px;
        height: 140px;
        background-color: #fff;
        border-color: #000;
        color: #000;
        text-shadow: unset;

    }
    
}
div.ant-modal.editParticipantModal .ant-form-item-label label {
    white-space: normal;
}

div.ant-modal.editParticipantModal .ant-modal-body,
div.ant-modal.editParticipantModal .ant-modal-header {
    padding: 8px;
}

div.ModalRunResult button.ant-btn.ant-btn-primary[disabled] {
    display: none;
}

button.ant-btn.eframeDisabled{
    border: 1px solid #dddfe2;
    color: gray;
    box-shadow: 0 3px 3px rgba(0,0,0,0.19), 0 3px 3px rgba(0,0,0,0.19);
}

@keyframes animationHighlight {
    from {
        border: 1px red solid;
        color: white;
    }
    to {
        border: 2px yellow solid;
        color: yellow;
    }
}
.animationHighlighting {
    animation: animationHighlight 1s linear 1s infinite alternate;
}
@keyframes animationTempoHighlight {
    0% {border: 5px red solid;}
    100% {border: 5px yellow solid;}
}

@keyframes animationHighlightCheckBox {
    from {
        border: 1px red solid;
    }
    to {
        border: 2px yellow solid;
    }
}
.animationHighlightingCheckBox {
    animation: animationHighlightCheckBox 1s linear 1s infinite alternate;
}
@keyframes animationTempoHighlight {
    0% {border: 5px red solid;}
    100% {border: 5px yellow solid;}
}

.animationTempoHighlighting {
    animation-name: animationTempoHighlight;
    animation-duration: 1s;
    animation-iteration-count: 3;
}
span.ant-radio {
    border: 2px solid #bcbce5;
    border-radius: 16px;
}
.canSubmitRunBtn {
    height: auto;
    white-space: normal;
}

.ant-select-search__field__mirror {
    left: 0;
}
.ant-layout-header {
    line-height: unset;
}

a.ant-btn.baseBtn {
    line-height: unset;
}

.baseBtn,
.ant-btn-primary
 {
    background:#f9a01e;
    color: white;
	display:inline-block;
	cursor:pointer;
	font-family:"Roboto",sans-serif;
}

.baseBtn:hover,
.baseBtn:active {
    text-shadow:0px 1px 0px #1570cd;
}

button,
button.ant-btn,
.baseBtn,
.ant-btn-primary
 {
	padding:3px 15px;
	text-decoration:none;
    text-align: center;
    border-radius: 10px;
    font-size:15px;
}
.ant-btn-sm.ant-btn-sm {
    padding: 0 7px
}

button.ant-btn[disabled] {
    background: white;
}

button.delete {
    background: transparent !important;
    padding: 0;
}

button:hover,
.ant-btn-primary:hover,
button.ant-btn:hover
 {
    color:white;
    text-decoration: none;
    box-shadow: 0 3px 3px rgba(0,0,0,0.15), 0 2px 3px rgba(0,0,0,0.32);
    /* background:#40a9ff; */
}

.baseBtn:active,
button:active,
.ant-btn-primary:active,
button.ant-btn:active
 {
    color:white;
    text-decoration: none;
}

.ant-btn-primary.disabled,
.ant-btn-primary[disabled],
.ant-btn-primary.disabled:hover,
.ant-btn-primary[disabled]:hover,
.ant-btn-primary.disabled:focus,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary.disabled:active,
.ant-btn-primary[disabled]:active,
.ant-btn-primary.disabled.active,
.ant-btn-primary[disabled].active {
    background:linear-gradient(to bottom, #f5f5f5 5%, #f5f5f5 100%);
    box-shadow: unset;
    color: #bfbfbf !important;
}

button.ant-modal-close {
    border: none;
    background: none;
    box-shadow: none;
}

.dividedBox button.changeDeliveryAddressBtn {
    margin: 0 0 3px 8px;
}

.dividedBox {
  margin: 10px;
  padding: 11px;
  background: white;
  /* border: 1px solid #dddfe2; */
  border-radius: 20px;
}
.dividedBox h1.condition {
    font-size: 20px;
    color: black;
    text-align: left;
    margin: unset;
}

.dividedBox button.changeDeliveryAddressBtn {
    margin: 0 0 3px 8px;
}

.dividedBox.bigFont {
    color: black;
    font-size: 21px;
    font-family: 'Roboto', 'Sukhumvit Set';
}

.dividedBoxdeliver.bigFont {
    color: black;
    font-size: 21px;
    font-family: 'Roboto', 'Sukhumvit Set';
}

.dividedBoxdeliver {
    margin: 10px 8px;
    padding: 11px;
    background: white;
    /* border: 1px solid #dddfe2; */
}

.dividedBoxdeliver h1 {
    /* border-bottom: 1px solid #dfe3e8; */
    font-family: 'Roboto', 'Sukhumvit Set';
    font-size: 22px;
    color: black;
    /* color: #f9a01e; */
}

.deliveryAddressBox {
    font-family: 'Roboto', 'Sukhumvit Set';
}
/* div.dividedBox .deliveryAddressPreviewBoxWrapper h1 {
    color: black;
    margin-top: 20px;
} */

div.radioGroupBtn .ant-radio-wrapper{
    touch-action: manipulation;
    cursor: pointer;
    border: 2px solid #d9d9d9;
    border-radius: 15px;
    padding: 12px;
    font-family: 'Sukhumvit Set';
    color: black;
    background: #d9d9d9;
    margin: 5px 3px;
    vertical-align: top;
}
div.radioGroupBtn .ant-radio-wrapper.ant-radio-wrapper-checked {
    color: #f9a01e;
    border: 2px solid #f9a01e;
    background: #ffefd8;
}
div.radioGroupBtnNew .ant-radio-wrapper{
    touch-action: manipulation;
    cursor: pointer;
    border: 2px solid #eee;
    border-radius: 8px;
    padding: 13px 9px;
    font-family: 'Sukhumvit Set';
    color: black;
    /* background: #eee; */
    margin: 5px 3px;
    vertical-align: top;
}
div.radioGroupBtnNew .ant-radio-wrapper.ant-radio-wrapper-checked {
    color: #000000;
    border: 2px solid #f9a01e;
    background: linear-gradient(0deg, rgba(249, 160, 27, 0.04), rgba(249, 160, 27, 0.04)), #FFFFFF;
}
.ant-radio-inner::after {
    background-color: #F9A01B !important;
}
.ant-radio-checked .ant-radio-inner {
    background-color: #F9A01B !important;
}
.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
    border-color: #fff !important;
}
div.radioGroupBtns .ant-radio-button-wrapper{
    touch-action: manipulation;
    cursor: pointer;
    border: 2px solid #eee;
    border-radius: 8px;
    padding: 13px 9px;
    font-family: 'Sukhumvit Set';
    color: black;
    background: #eee;
    margin: 5px 3px;
    vertical-align: top;
}
div.radioGroupBtnNes .ant-radio-button-wrapper-checked {
    color: #000000;
    border: 2px solid #f9a01e;
    background: #ffefd8;
    border-left: 2px solid #f9a01e !important;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child{
    border-color: #f9a01e !important;
}
.ant-radio-button-wrapper:not(:first-child)::before{
    display: none !important;
}
div.historyList button {
    margin: 2px;
}

.modCheckBox {
    touch-action: manipulation;
    cursor: pointer;
    border: 2px solid #d9d9d9;
    border-radius: 15px;
    padding: 12px;
    font-family: 'Roboto', 'Sukhumvit Set';
    color: black;
    background: #d9d9d9;
    margin: 5px 3px;
}

.modCheckedBox {
    touch-action: manipulation;
    cursor: pointer;
    border-radius: 15px;
    padding: 12px;
    font-family: 'Roboto', 'Sukhumvit Set';
    color: #2590ff;
    border: 2px solid #2590ff;
    background: rgba(24, 144, 255, 0.2);
    margin: 5px 3px;
}

.modCheckedBox span.ant-checkbox {
    border: 2px solid #2590ff;
}

.ant-collapse-content > .ant-collapse-content-box {
    padding: 8px;
}
.ant-tag-green {
    color: white;
    background: #72c22c;
    border-color: #b7eb8f;
}
div.removeRuns {
    margin: -15px;
}
div.removeRuns .ant-row {
    padding: 8px 0 3px;
    text-align: center;
    background: lightgray;
    margin-bottom: 8px;
}

div.removeRuns div.preview {
    display: inline-block;
    background-size: cover;
    background-position: 50%;
    width: 300px;
    height: 308px;
}
@media only screen and (max-width: 600px) {
    /* div.AddParticipantLink {
        margin-right: 20px;
    } */
    div.dividedBox {
        margin: 20px 10px;
    }
    div.removeRuns div.preview {
        width: 188px;
        height: 208px;
    }
    div.priviledgeInput.priviledgeInput {
        top: 0px;
    }
    div.priviledgeInputForm.priviledgeInputForm .ant-form-extra {
        position: relative;
        top: 0px;
    }
    div.priviledgeInputForm.priviledgeInputForm .ant-form-explain {
        position: relative;
        top: 0px;
    }
}
div.priviledgeInputForm .ant-form-item-control .ant-form-item-children {
    display: flex;
    max-width: 423px;
}
div.priviledgeInput {
    top: 39px;
}

div.priviledgeInputForm .ant-form-extra {
    position: relative;
    top: 39px;
}
div.priviledgeInputForm .ant-form-explain {
    position: relative;
    top: 39px;
}
@media only screen and (max-width: 375px) {
    div.removeRuns div.preview {
        width: 172px;
        height: 178px;
    }
}
@media only screen and (max-width: 320px) {
    div.removeRuns div.preview {
        width: 150px;
        height: 172px;
    }
}
textarea {
    width: 100%;
    border-color: #d9d9d9;
}

@keyframes home-bg-color {
    from {background-color: white;}
    to {background-color: black;}
}
@keyframes home-bg-color2 {
    from {background-color: black;}
    to {background-color: white;}
}

div.head-topic2 {
    position: relative;
    z-index: 3;
    margin: 0em;
    min-height: 185px;
    
}

div.head-topic {
    position: relative;
    z-index: 3;
    margin: 0em;
    min-height: 185px;
    padding: 3em 0em;
    background-color: #FFFFFF;
    border-bottom: 1px solid #DDDDDD;
    box-shadow: none;
    position: relative;
    overflow: hidden;
    text-align: center;
    padding: 0em;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 0px;
    border-bottom: none;
    background-color: black;
    /* animation-name: home-bg-color; */
    animation-duration: 5s;
    background-position: 50% 50%;
    transform: translate3d(0, 0, 0);
}

div.card-wrapper-bg {
    position: relative;
    z-index: 3;
    box-shadow: none;
    position: relative;
    overflow: hidden;
    text-align: center;
    background-color: white;
    /* animation-name: home-bg-color2; */
    animation-duration: 5s;
    background-position: 50% 50%;
    transform: translate3d(0, 0, 0);
}
div.card-wrapper-bg:after {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: '';
    background-size: cover;
    opacity: 0.45;
    /* background-image: url(/home-bg.jpg); */
    background-color: #ffffff;
}
div.card-wrapper-bg,
div.card-wrapper-bg:after {
    transition: background 6s cubic-bezier(0.680, -0.550, 0.265, 1.4) 0s, opacity 6s cubic-bezier(0.680, -0.550, 0.265, 1.4) 0s;
}

div.head-topic:after {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: '';
    background-size: cover;
    opacity: 0.45;
    background-image: linear-gradient(315deg, #eec0c6 0%, #7ee8fa 74%);
    /* background-image: url(/home-bg.jpg); */
}
div.head-topic,
div.head-topic:after {
    transition: background 6s cubic-bezier(0.680, -0.550, 0.265, 1.4) 0s, opacity 6s cubic-bezier(0.680, -0.550, 0.265, 1.4) 0s;
}
div.head-topic div.head-text-container {
    /* height: 55vh; */
    margin-left: auto !important;
    margin-right: auto !important;
    /* min-height: 449px; */
    min-height: 293px;
    align-items: center;
    display: flex;
    justify-content: center;
}
div.head-topic div.head-text-container div.introduction {
    position: relative;
    clear: both;
    display: block;
    text-align: center;
}
div.head-topic div.head-text-container div.introduction h1 {
    font-size: 3em;
    color: rgba(255, 255, 255, 1);
    line-height: 1.2;
    margin: 0px 0px 0px;
    padding-bottom: 0px;
    perspective: 500px;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    transform-style: preserve-3d;
    font-weight: normal;
    border: none;
    /* margin: 0 1rem; */
    padding: 0 0;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    text-transform: none;
}
div.head-topic div.head-text-container div.introduction div.text-a {
    font-size: 1.75em;
    font-weight: bold;
}
div.head-topic div.head-text-container div.introduction div.text-b {
    font-size: 0.75em;
}
div.head-topic div.head-text-container div.introduction div.text-c {
    margin-top: 8px;
    font-size: 0.65em;
}
div.head-topic div.head-text-container div.introduction div.text-c img {
    width: 160px;
}
@media only screen and (max-width: 375px) {
    div.head-topic div.head-text-container div.introduction div.text-b div.text-b-b {
        display: block;
    }
    div.head-topic div.head-text-container div.introduction div.text-c img {
        width: 80px;
    }
}
div.head-topic div.head-text-container div.introduction div.divider {
    border-color: transparent!important;
    font-size: 1rem;
    margin: 1rem 0;
    line-height: 1;
    height: 0;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .05em;
    color: rgba(0,0,0,.85);
    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}
div.head-topic div.head-text-container div.introduction button.eventFormBtn {
    font-size: 1.42857143rem;
    box-shadow: 0 0 0 2px #fff inset!important;
    background: transparent none !important;
    color: #fff!important;
    text-shadow: none!important;
    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    height: 51.41px;
    outline: 0;
    border: none;
    vertical-align: baseline;
    background: #e0e1e2 none;
    color: rgba(0,0,0,.6);
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    margin: 0 .25em 0 0;
    padding: .78571429em 1.5em .78571429em;
    text-transform: none;
    text-shadow: none;
    font-weight: 700;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    border-radius: .28571429rem;
    box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34,36,38,.15) inset;
    -webkit-user-select: none;
    user-select: none;
    transition: opacity .1s ease,background-color .1s ease,color .1s ease,box-shadow .1s ease,background .1s ease;
    will-change: '';
    -webkit-tap-highlight-color: transparent;
}
div.head-topic div.head-text-container div.introduction button.eventFormBtn:hover {
    background: white!important;
    color: black!important;
}
div.wrapBottomPosFixedBtn button.bottomBtnPosFixed {
    position: fixed;
    bottom: 8px;
    left: 8px;
    z-index: 3;
}
div.wrapBottomPosFixedBtn .bottomBtnPosFixed {
    position: fixed;
    bottom: 8px;
    left: 8px;
    z-index: 3;
}
@keyframes animationHighlightTag {
    from {
        border: 1px red solid;
        color: red;
        font-size: large;
    }
    to {
        border: 2px rgb(255, 0, 242) solid;
        color: black;
    }
}

div.testMode {
    position: fixed;
    top: 3px;
    z-index: 8;
}
div.testMode .ant-tag {
    animation: animationHighlightTag 1s linear 1s infinite alternate;
}

@media only screen and (max-width : 3020px) {
    div.imageModal .ant-modal-content {
        width: 700px;
    }
    div.imageModal .ant-modal-body {
        padding: 9px;
    }
    
    div.imageModal .ant-modal-confirm-content {
        margin: 0 !important;
    }
    
    div.imageModal .ant-modal-body img{
        width: calc(700px - 18px);
    }
}
@media only screen and (max-width : 810px) {
    div.participantsTopAction {
        display: none;
    }
    div.participantsMsTopAction.participantsMsTopAction {
        /* margin-top: 10px; */
        display: block;
        font-size: 17px;
        margin: 3px 0;
    }
    /* div.participantsTopAction span.addWP {
    } */
    
}
@media only screen and (max-width : 700px) {
    div.imageModal .ant-modal-content {
        width: 663px;
    }
    div.imageModal .ant-modal-body {
        padding: 9px;
    }
    
    div.imageModal .ant-modal-confirm-content {
        margin: 0 !important;
    }
    
    div.imageModal .ant-modal-body img{
        width: calc(663px - 18px);
    }
}
@media only screen and (max-width : 600px) {
    div.head-topic div.head-text-container div.introduction h1 {
        font-size: 1.8em !important;
    }
    div.head-topic div.head-text-container {
        min-height: 241px;
        padding: 3rem 0rem;
        margin-top: 0;
    }
    div.imageModal .ant-modal-content {
        width: 463px;
    }
    div.imageModal .ant-modal-body {
        padding: 9px;
    }
    
    div.imageModal .ant-modal-confirm-content {
        margin: 0 !important;
    }
    
    div.imageModal .ant-modal-body img{
        width: calc(463px - 18px);
    }
}
@media only screen and (max-width : 400px) {
    div.head-topic div.head-text-container div.introduction h1 {
        font-size: 1.5em !important;
    }
    div.head-topic div.head-text-container {
        padding: 0rem 0rem;
    }
    div.imageModal .ant-modal-content {
        width: 363px;
    }
    div.imageModal .ant-modal-body {
        padding: 9px;
    }
    
    div.imageModal .ant-modal-confirm-content {
        margin: 0 !important;
    }
    
    div.imageModal .ant-modal-body img{
        width: calc(363px - 18px);
    }
}

label.ant-radio-wrapper {
    word-break: break-all;
    white-space: pre-wrap;
}

div.lottoSubmitBtn {
    position: -webkit-sticky;
    position: sticky;
    z-index: 3;
    bottom: 0;
    text-align: center;
    padding: 3px;
    border-top: 1px solid #d2cdcde3;
    background: white;
}

div.head-topic div.text-d {
    margin-top: 19px;
    font-size: 30px;
}

div.head-topic div.text-e img{
    width: 100%;
}
div.head-topic div.head-text-container div.introduction .text-b-b {
  margin-top: 10px;
  font-size: 28px;
}
@media only screen and (max-width : 600px) {
    div.head-topic div.text-d {
        margin-top: 9px;
        font-size: 19px;
    }
    div.head-topic div.head-text-container div.introduction .text-b-b {
      margin-top: 10px;
      font-size: 18px;
    }
}
button.ant-btn.paymentButton.ant-btn-primary {
    width: 100%;
    max-width: 156px;
}
  
div.searchEventWrapperA {
    display: flex;
    /* justify-content: center; */
}
div.searchEventWrapperB {
    /* width: 990px; */
}

span.seachEventInput {
    width: 300px;
    margin-left: 88px;
    position: fixed;
    z-index: 8;
    top: 15px;
}

span.seachEventInput i {
    color: black;
}

@media only screen and (max-width : 435px) {
    .popup {
        top: 0;
    }
    span.seachEventInput {
        width: 200px;
    }
}
@media only screen and (max-width : 335px) {
    span.seachEventInput {
        width: 178px;
    }
}

.displayNone {
    display: none;
}
/* customizable snowflake styling */
/* .snowflake {
    color: #fff;
    font-size: 1em;
    font-family: Arial;
    text-shadow: 0 0 1px #000;
  }
  
  @-webkit-keyframes snowflakes-fall{0%{top:-10%}100%{top:100%}}@-webkit-keyframes snowflakes-shake{0%{-webkit-transform:translateX(0px);transform:translateX(0px)}50%{-webkit-transform:translateX(80px);transform:translateX(80px)}100%{-webkit-transform:translateX(0px);transform:translateX(0px)}}@keyframes snowflakes-fall{0%{top:-10%}100%{top:100%}}@keyframes snowflakes-shake{0%{transform:translateX(0px)}50%{transform:translateX(80px)}100%{transform:translateX(0px)}}.snowflake{position:fixed;top:-10%;z-index:9999;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;cursor:default;-webkit-animation-name:snowflakes-fall,snowflakes-shake;-webkit-animation-duration:10s,3s;-webkit-animation-timing-function:linear,ease-in-out;-webkit-animation-iteration-count:infinite,infinite;-webkit-animation-play-state:running,running;animation-name:snowflakes-fall,snowflakes-shake;animation-duration:10s,3s;animation-timing-function:linear,ease-in-out;animation-iteration-count:infinite,infinite;animation-play-state:running,running}.snowflake:nth-of-type(0){left:1%;-webkit-animation-delay:0s,0s;animation-delay:0s,0s}.snowflake:nth-of-type(1){left:10%;-webkit-animation-delay:1s,1s;animation-delay:1s,1s}.snowflake:nth-of-type(2){left:20%;-webkit-animation-delay:6s,.5s;animation-delay:6s,.5s}.snowflake:nth-of-type(3){left:30%;-webkit-animation-delay:4s,2s;animation-delay:4s,2s}.snowflake:nth-of-type(4){left:40%;-webkit-animation-delay:2s,2s;animation-delay:2s,2s}.snowflake:nth-of-type(5){left:50%;-webkit-animation-delay:8s,3s;animation-delay:8s,3s}.snowflake:nth-of-type(6){left:60%;-webkit-animation-delay:6s,2s;animation-delay:6s,2s}.snowflake:nth-of-type(7){left:70%;-webkit-animation-delay:2.5s,1s;animation-delay:2.5s,1s}.snowflake:nth-of-type(8){left:80%;-webkit-animation-delay:1s,0s;animation-delay:1s,0s}.snowflake:nth-of-type(9){left:90%;-webkit-animation-delay:3s,1.5s;animation-delay:3s,1.5s}
  /* end customizable snowflake styling */
  /* firework */
  /* .fireworks {
      position: absolute;
      left: 0;
  }
  div.head-topic div.head-text-container {
      background-color: black;
  }
  @keyframes animationHighlightTagAA {
    from {
        text-shadow: -1px 0 #FFC107, 0 1px rgb(255, 242, 126), 3px 0 #FFC107, 0 -1px #FFEB3B;
        font-size: 1.75em;
    }
    to {
        font-size: 1.75em;
        text-shadow: -1px 0 rgb(255, 153, 0), 0 1px rgb(177, 59, 255), 1px 0 rgb(7, 156, 255), 0 -1px #FFEB3B;
    }
}

div.text-a-a {
    animation: animationHighlightTagAA 2s linear 1s infinite alternate;
    font-size: 1.55em;
    color: black; */
    /* text-shadow: -1px 0 #FFC107, 0 1px #FFEB3B, 3px 0 #FFC107, 0 -1px #FFEB3B; */
    /* color: rgba(255, 255, 255, 0); */
/* } */
/* firework */
.Roboto {
    font-family: 'Roboto', 'Sukhumvit Set'
}
div.text-a {
    color: #03a8de;
}
.displayNone {
    display: none;
}


/* unvisited link */
.aLink {
    /* color: rgb(0, 132, 255); */
    color: #666;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
}
.aLink:link {
    color: red;
}

/* visited link */
.aLink:visited {
    color: green;
}

/* mouse over link */
.aLink:hover {
    /* color: hotpink; */
    color: rgb(0, 132, 255);
}

/* selected link */
.aLink:active {
    color: blue;
}

.ticketGroupForm {
    margin: 0;
    /* margin-bottom: 3px; */
}
/* .ticketGroupWrapper label.bigTitle {
    width: 300px;
} */
.ticketGroupWrapper label.bigTitle span {
    font-size: 21px;
    padding: 0 3px;
}
div.ticketGroupWrapper {
    border-radius: 5px;
    padding: 0 2px;
    margin: 0;
    margin-bottom: 3px;
}
.ticketGroupWrapper .afterTicketGroup {
    padding: 0 30px
}
.ticketGroupWrapper .afterTicketGroup .ant-row.ant-form-item {
    margin: 0;
    margin-bottom: 15px;
}
div.ticketGroupPreviewWrapper {
    border-radius: 5px;
    padding: 0 2px;
    margin: 0;
    margin-bottom: 3px;
}
.dividedBoxRegistrationSteps {
    position: relative;
    max-width: 700px;
    margin: auto;
    zoom: 1.3;
}
.dividedBoxRegistrationStepsBG {
    width: 100%;
    height: 0;
}
.dividedBoxRegistrationStepsBG::before {
    content: "+";
    position: relative;
    display: inline-block;
    height: 157px;
    color: transparent;
    width: 100%;
    border-radius: 0 0 100% 100%;
    background: #f7f7f7;
}
.races-layout-content {
    display: 'flex';
    justify-content: 'center';
    align-items: 'flex-start';
    max-width: '1250px';
    width: '100%';
    /* background: 'white'; */
    width: 100%;
    max-width: 1250px;
}
.ant-steps-item-icon {
    font-family: 'Roboto', 'Sukhumvit Set';
}
/* div.dividedBoxRegistrationSteps .ant-steps-item-process .ant-steps-item-icon {
    background: #f9a01e;
    border-color: #f9a01e;
    color: white;
} */
/* div.dividedBoxRegistrationSteps .ant-steps-item-finish .ant-steps-item-icon {
    background: #f9a01e;
    border-color: #f9a01e;
    color: white;
} */
div.dividedBoxRegistrationSteps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    /* background: #f9a01e;
    border-color: #f9a01e; */
    color: white;
}
div.dividedBoxRegistrationSteps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background: #f9a01e;
}
.ant-row-space-between {
    justify-content: 'flex-start';
    display: flex;
    flex-flow: row wrap;
}
button.ant-btn.back {
    background: white;
    color: black;
    text-shadow: unset;
    border-color: black;
    border-radius: 20px;
}
button.ant-btn.submit {
    background: #f9a01e;
    text-shadow: unset;
    border-color: #f9a01e;
    /* border-radius: 7px; */
    /* font-size: 23px; */
    color: white;
}
div.dividedBoxRegistrationSteps {
    margin-bottom: 1em;
}
.customRadio span.ant-radio {
    display: none;
}
div.customRadio {
    width: 100%;
}
div.customRadio .ant-radio-wrapper {
    border-radius: 8px;
    width: 100%;
    padding: 0 19px;
    margin: 5px 0;
}
div.customRadio div.action {
    text-align: right;
    height: 0;
}
div.participantAction {
    text-align: right;
    height: 0;
}
div.participantsMsTopAction {
    display: none;
}
div.participantsTopAction {
    text-align: right;
    height: 0;
}
div.participantsTopAction span.addWP {
    font-size: 18px;
    /* color: #1890ff; */
    position: relative;
    right: 5px;
    z-index: 1;
}
div.participantAction span {
    font-size: 20px;
    position: relative;
    top: 3px;
}
div.participantAction i {
    font-size: 20px;
}
.ant-modal-content {
    padding: 9px;
    border-radius: 20px;
}
a.titleAddAction {
    font-weight: normal;
    font-size: small;
    position: relative;
    z-index: 1;
    /* color: #1890ff; */
}
.ant-modal-title {
    font-size: 20px;
    padding: 5px;
}
span.ant-modal-close-x {
    font-size: 23px;
    color: black;
}
.preLabelIcon {
    font-size: 20px;
    margin-right: 10px;
    position: relative;
    top: 5px;
}
div.submitRegistrationWrapper {
    text-align: center;
    padding: 8px;
    margin-bottom: 20px;
}
div.submitRegistrationWrapper button {
    background: #f9a01e;
    border-color: #f9a01e;
    padding:0px 37px;
   /*  box-shadow: 0 1px 1px 0 #FFD700, 0 3px 10px 0 #FFD700;
    font-size: 1.5em; */
}
div.AddParticipantLink {
    text-align: center;
    /* padding: 8px; */
    /* margin-bottom: 20px; */
}
div.AddParticipantLink button {
    background: #ffefd8;
    color: #f9a01e;
    border: 2px solid #f9a01e;
    /* padding:0px 25px; */
    box-shadow: unset;
    /* font-size: 1.5em; */
}
img.imgBranner {
    max-height: 358px;
    max-width: 1142px;
    width: 100%;
    padding-bottom: 20px;
    margin: auto;
}
.ant-spin-container {
    text-align: left;
}
.ant-row-center {
    justify-content: center;
}
.ant-row-top {
    align-items: flex-start;
}
div.facebookBox {
    /* border-radius: 29px; */
    background-color: #3a559f;
    border-color: #3a559f;
    /* background: linear-gradient(#284d97, #5174bf); */
    /* background-color: #29487d; */
    box-shadow: inset 0 1px 1px #2d2f33;
    /* border-color: #07142e #1c3362 #13171e; */
    /* border: 1px solid #9c7c7d; */
    height: 47px;
}
div.facebookBox:hover {
    /* border-radius: 29px; */
    background: linear-gradient(#7d97c7, #5a6885) !important;
    border-color: #29487d;
}
div.facebookBox:active {
    /* border-radius: 29px; */
    background-color: #333f56;
    border-color: #29487d;
}
.largeBtn.largeBtn {
    padding: 0px 52px;
    box-shadow: 0 1px 1px 0 #FFD700, 0 3px 10px 0 #FFD700;
    font-size: 1em;
    height: auto;
}
.largeBtn2.largeBtn2 {
    padding: 0px 21px;
    box-shadow: 0 1px 1px 0 #FFD700, 0 3px 10px 0 #FFD700;
    font-size: 20px;
    height: auto;
}
.MuiSelect-select.Mui-disabled {
    cursor: not-allowed !important;
}
.ant-modal-confirm .ant-modal-body {
    padding: 19px;
}
.bmiTd {
    border: 1px solid;
    padding-left: 12px;
}
.deliveryBlock span.label {
 color: black;
}
.deliveryBlock .ant-badge-status-dot {
    width: 11px;
    height: 11px;
    margin-left: 3px;
}
.deliveryBlock a {
    color: #f9a01c;
    font-weight: 500;
    font-size: larger;
}
.ant-select-dropdown-menu-item {
    overflow: visible;
    white-space: normal;
    text-overflow: unset;
    border-top:1px dotted rgb(213, 210, 210) ;
}
.deliveryAddressPreviewBoxWrapper button {
    font-size: x-large;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.deliveryAddressFormModal .ant-form-item{
    margin-bottom: 8px;
}
.deliveryAddressFormModal .ant-modal-content {
    width: 100%;
}
a.privacy-policy-footer {
    color: white;
    font-weight: 400;
    font-size: small;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
}
a.privacy-policy-login {
    color: #666;
    /* font-weight: 400; */
    /* font-size: small; */
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
}
div.ant-modal.paymentPopup  .ant-modal-content {
  padding: 0px;
  border-radius: 8px;
}
div.ant-modal.paymentPopup  .ant-modal-title  {
  font-size: 20px;
  text-align: center;
  padding-right: 20px;
}
div.ant-modal.paymentPopup  .ant-modal-footer  {
  padding: 20px;
}
div.ant-modal.participantListModal  .ant-modal-content {
  padding: 0px;
  border-radius: 8px;
}
div.ant-modal.participantListModal  .ant-modal-title  {
  font-size: 20px;
  text-align: center;
  padding-right: 20px;
}
div.ant-modal.participantListModal  .ant-modal-footer  {
  padding: 20px;
}

.head-page-user .banner-mobile {
    display: none;
}
.head-page-user {
    width: auto;
    /* min-height: 90vh; */
    /* background-image: url("/register-bg.png");
    background-size: cover; */
    background: #3e5b69;
    /* background-position: center;
    display: flex; */
    /* margin-top: 90px; */
    /* flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: -10px; */
    position: relative;
    text-align: center;
}
.head-page-user .centered {
    width: 100%;
    position: absolute;
    top: 63px;
    padding-left: 20%;
    padding-right: 8px;
    /* left: 50%;
    transform: translate(-50%, -50%); */
    text-align: left;
}
.dividedBox {
    padding: 0px 24px;
}
.event-register-organizer{
    font-family: 'Roboto', 'Sukhumvit Set';
    color: #FFFFFF;
    font-size: 16px;
    /* margin-top: 100px; */
    /* padding: 0 20%; */
    /* position: relative;
    z-index: 1; */
}
.event-register-name{
    font-family: 'Roboto', 'Sukhumvit Set';
    font-style: normal;
    font-weight: normal;
    color: #FFFFFF;
    font-size: 38px;
    /* margin-top: 100px; */
    word-wrap: break-word;
    /* padding: 10px 20%; */
    word-break: break-word;
    margin: 0;
}

@media screen and (max-width: 1088px) {
    .event-register-name{
        font-size: x-large;
    }
}
@media screen and (max-width: 767px) {
    .head-page-user {
        width: auto;
        /* min-height: 300px !important; */
    }
    .head-page-user .banner-mobile {
        display: unset;
    }
    .head-page-user {
        background: unset;
    }
    .banner {
        display: none;
    }
    .head-page-user .centered {
        top: 16px;
        padding-left: 24px;
        text-align: left;
    }
	.event-register-name {
			/* margin-top: 25px; */
			padding: 0px;
			font-size: 36px;
            font-size: x-large;
	}
	.event-register-organizer { 
			/* margin-top: 50px; */
			padding: 0px;
            
	}

}

@media screen and (max-width: 526px ) {
    .head-page-user .event-register-name {
        font-size: 21px;
        max-width: 290px;
    }
    .head-page-user .event-register-organizer { 
        font-size: 12px;
    }
  .regInformation h1 {
    font-size: 14px;
  }
  .regInformation h2 {
    font-size: 24px;
  }
  .regInformation h3 {
    font-size: 18px;
  }
  .regInformation div > h4 {
    width: 140px;
  }
  .regInformation div > p {
    width: 80%;
  }
  .head-page-user {
    width: auto;
    /* min-height: 200px !important; */
	}
    .head-page-user .centered {
        /* top: 28%; */
        text-align: left;
        /* margin-top: 28px; */

    }
	
}

@media screen and (max-width: 376px) {
    .head-page-user {
        width: auto;
        /* min-height: 200px !important; */
    }
    .head-page-user .centered {
        /* top: 28%; */
        /* margin-top: 28px; */
      }
    .head-page-user .event-register-name {
        /* margin-top: 25px; */
        /* padding-left: 24px; */
        font-size: 20px;
    }
    .head-page-user .event-register-organizer { 
        /* margin-top: 50px; */
        /* padding-left: 24px; */
    }
    .row-padding.row-padding {
        padding-left: 0px;
    }
    .dividedBox.dividedBox {
        padding: 0 10px;
    }
}
.orderformmodal .ant-form-item-label > label::after {
    display: none;
}
.orderformmodal .ant-form-item-label label {
    text-align: left;
    display: block;
}
.orderformmodal .PrivateNotchedOutline-legendLabelled-3 > span {
    width: 0;
    padding: 0;
}
.orderformmodal .ant-form legend {
    width: 0;
    /* padding: 0; */
}
.orderformmodal .ant-form-item-label > label::after {
    display: none;
}
.orderformmodal .ant-form-item-label label {
    text-align: left;
    display: block;
}
.orderformmodal .PrivateNotchedOutline-legendLabelled-3 > span {
    width: 0;
    padding: 0;
}
.orderformmodal .ant-form legend {
    width: 0;
    /* padding: 0; */
}
.cancelform .ant-form-item-label > label::after {
    display: none;
}
.cancelform .ant-form-item-label label {
    text-align: left;
    display: block;
}
.cancelform .PrivateNotchedOutline-legendLabelled-3 > span {
    width: 0;
    padding: 0;
}
.cancelform .ant-form legend {
    width: 0;
    /* padding: 0; */
}
/* .orderedItemsPreview .ant-card.ant-card-bordered{
    border: none;
}
.orderedItemsPreview .ant-col.ant-col-xs-24.ant-col-md-12{
    border: 1px solid #e8e8e8
} */
.orderedItemsPreview .ant-card-meta-detail {
    overflow: visible;
}
.orderedItemsPreview .ant-card-meta-title {
    overflow: visible;
}
.orderedItemsPreview .desc {
    color: gray;
    font-size: smaller;
    max-width: 228px;
    /* white-space: normal; */
    height: 19px;
}
.text-ellipsis {
    white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;

}
.itemBlock .ant-card-body {
    padding-top: 15px;
}
.itemBlock .display2lines {
    white-space: pre-line;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: smaller;
    height: 39px;
}

.rmcolonlabel .ant-form-item-label > label::after {
    display: none;
}
.MuiInputBase-input.Mui-disabled {
    background: #c4c4c4;
}

.middleMessage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
