.inputBD .ant-input  {
    border-radius: none !important;
    -webkit-border-radius: none !important;
    -moz-border-radius: none !important;
    -ms-border-radius: none !important;
    -o-border-radius: none !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 1px solid #d9d9d9 !important;
}

.inputBD .ant-input:hover  {
    border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -ms-border-radius: 0px !important;
    -o-border-radius: 0px !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 3px solid rgba(0, 0, 0, 0.65) !important;
}
